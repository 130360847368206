import EventQueue from '../util/event-queue';
const INTERVAL = 1000;
const PROCESS_PER_INTERVAL = 20;
class EventServiceClass extends EventQueue {
    constructor() {
        super(INTERVAL, PROCESS_PER_INTERVAL);
    }
    async dispatchEvent(event) {
        this.emit('event', event);
    }
    // public reportEvent(eventType: string, eventData: DocEventData) {
    //   const event = createDocEvent({ eventType, eventData });
    //   this.queueEvent(event);
    // }
    // public reportPortalEvent(portalEvent: DocPortalEvent) {
    //   const event = createDocEvent(portalEvent)
    //   this.queueEvent(event);
    // }
    reportEvent(portalEvent) {
        this.queueEvent(portalEvent);
    }
}
const EventService = new EventServiceClass();
export default EventService;
