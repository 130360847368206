export class GTagEventTarget {
    constructor(eventSource) {
        eventSource.on('event', (event) => {
            // const gtag = window['gtag'];
            // if (!gtag || !(gtag instanceof Function)) {
            //   console.warn('GTagEvent: missing window.gtag(), cannot send gtag events');
            //   return
            // }
            // window['dataLayer'] = window['dataLayer'] || [];
            // function gtag(...args: any[]){window['dataLayer'].push(...args);}
            const eventType = event.portalEvent?.eventType ?? 'unknown';
            const gtag = window['gtag'];
            if (gtag) {
                gtag('event', `toplineDocPortalEvent-${eventType}`, JSON.stringify(event));
            }
        });
    }
}
