import EventQueue from "../util/event-queue";
import { toDocEvent } from "../util/events";
const INTERVAL = 1000;
const PROCESS_PER_INTERVAL = 20;
class ApiEventServiceClass extends EventQueue {
    eventsServiceBaseUri;
    constructor() {
        super(INTERVAL, PROCESS_PER_INTERVAL);
        this.eventsServiceBaseUri = `${window.location.protocol}//events.${window.location.host}`.replace(/\/+$/g, '') + '/';
    }
    async makeRequest(portalEvent) {
        const uri = this.eventsServiceBaseUri + 'event';
        const docEvent = toDocEvent(portalEvent);
        const body = JSON.stringify(docEvent);
        console.info(`ApiEventService: event body: << ${body} >>`);
        const resp = await fetch(uri, { method: 'POST', body, headers: {
                'Content-Type': 'application/json',
            } });
        // if (resp.status == 200 || resp.status == 304) {
        // }
    }
    async dispatchEvent(event) {
        await this.makeRequest(event);
    }
    async deliverEvent(event) {
        this.queueEvent(event);
    }
}
const ApiEventService = new ApiEventServiceClass();
export default ApiEventService;
