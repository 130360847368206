import AnalyticsTracker from '../trackers/analytics';
export class AnalyticsEventTarget {
    constructor(eventSource) {
        eventSource.on('event', (portalEvent) => {
            const schema = `iglu:topline.futurimedia.com/doc_portal/events/pdf_viewer/jsonschema/1-0-0`;
            const selfDescribingEvent = {
                schema,
                data: { ...portalEvent },
            };
            // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/
            AnalyticsTracker.analytics.track('selfDescribingEvent', selfDescribingEvent);
        });
    }
}
