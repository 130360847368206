import Analytics from 'analytics';
import snowplowPlugin from '@analytics/snowplow';
import { SP_APP_ID, sp_collector_url } from '../consts';
import { LinkClickTrackingPlugin } from '@snowplow/browser-plugin-link-click-tracking';
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger';
// import { ErrorTrackingPlugin, enableErrorTracking } from '@snowplow/browser-plugin-error-tracking';
// import { GeolocationPlugin, enableGeolocationContext } from '@snowplow/browser-plugin-geolocation';
import PortalDocumentService from '../services/doc-service';
const setupAnalytics = () => {
    const analytics = Analytics({
        app: 'topline-delivery-portal',
        plugins: [
            snowplowPlugin({
                name: 'snowplow',
                collectorUrl: sp_collector_url,
                trackerSettings: {
                    appId: SP_APP_ID,
                    contexts: {
                        webPage: true,
                        performanceTiming: false,
                        gaCookies: true,
                    },
                    plugins: [
                        // ClientHintsPlugin(),
                        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/plugins/link-click-tracking/
                        LinkClickTrackingPlugin(),
                        DebuggerPlugin(),
                        // ErrorTrackingPlugin(),
                        // GeolocationPlugin(),
                    ]
                },
            })
        ]
    });
    analytics.on('initialize:snowplow', ({ instance }) => {
        // ENABLE ERROR TRACKING
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#error-tracking
        // ENABLE ACTIVITY TRACKING
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#enableactivitytracking
        // instance.plugins.snowplow.enableActivityTracking(10, 10);
        // ENABLE LINK CLICK TRACKING
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#link-click-tracking
        instance.plugins.snowplow.enableLinkClickTracking();
        // ENABLE ERROR TRACKING
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/plugins/error-tracking/
        //instance.plugins.snowplow.enableErrorTracking();
        // ENABLE GEOLOCATION
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/plugins/geolocation/
        // einstance.plugins.snowplow.nableGeolocationContext();
        // Only attaches contexts to schemas that aren't mailchimp vendor
        var ruleSetRejectVendor = {
            reject: ['iglu:com.snowplowanalytics.snowplow/payload_data/jsonschema/1-0-4']
        };
        // A filter that will only attach contexts to structured events
        function structuredEventFilter(args) {
            return args['eventType'] === 'ue';
        }
        function contextEntityToBeAdded() {
            return [() => PortalDocumentService.isPortalDocument, {
                    schema: 'iglu:topline.futurimedia.com/doc_portal/contexts/doc/jsonschema/1-0-0',
                    data: PortalDocumentService.getDocumentContextData(),
                }];
        }
        var globalContextDefinition = [ruleSetRejectVendor, structuredEventFilter, contextEntityToBeAdded];
        // ADD GLOBAL CONTEXT
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#global-context
        instance.plugins.snowplow.addGlobalContexts([
            globalContextDefinition
        ]);
        // ADD GLOBAL CONTEXT
        // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#global-context
        // instance.plugins.snowplow.addGlobalContexts([
        //   [() => PortalDocumentService.isPortalDocument, {
        //     schema: 'iglu:topline.futurimedia.com/doc_portal/contexts/doc/jsonschema/1-0-0',
        //     data: PortalDocumentService.getDocumentContextData(),
        //   }]
        // ])
        instance.page()
            .then(() => { console.info('trackers/analytics: page view complete'); });
    });
    return analytics;
};
export default class AnalyticsTracker {
    static _analytics;
    static get analytics() {
        if (!this._analytics) {
            this._analytics = setupAnalytics();
        }
        return this._analytics;
    }
    static setup() {
        this.analytics;
    }
    static async track(eventName, payload, options, callback) {
        await this.analytics.track(eventName, payload, options, callback);
    }
}
