import EventEmitter from 'eventemitter2';
const DEFAULT_INTERVAL = 1000;
const DEFAULT_PROCESS_PER_INTERVAL = 1;
export default class EventQueue extends EventEmitter {
    _interval = null;
    intervalSeconds;
    maxEventsPerInterval;
    eventQueue = [];
    constructor(intervalSeconds = DEFAULT_INTERVAL, maxEventsPerInterval = DEFAULT_PROCESS_PER_INTERVAL) {
        // EventEmitter
        super();
        this.intervalSeconds = intervalSeconds;
        this.maxEventsPerInterval = maxEventsPerInterval;
    }
    async processEvents(maxEvents) {
        let n = 0;
        while (true) {
            const event = this.eventQueue.pop();
            if (!event || n >= maxEvents) {
                break;
            }
            this.dispatchEvent(event);
        }
        return n;
    }
    stopInterval() {
        if (this._interval) {
            clearInterval(this._interval);
        }
        this._interval = null;
    }
    startInterval() {
        const interval = setInterval(() => {
            console.info(`EventService: interval triggered with ${this.eventQueue?.length ?? 0} events in queue.`);
            const numProcessed = this.processEvents(this.maxEventsPerInterval);
            console.info(`EventService: processed ${numProcessed} events.`);
        }, this.intervalSeconds);
        this._interval = interval;
    }
    queueEvent(event) {
        this.eventQueue.push(event);
    }
}
