export default class PortalDocumentService {
    static getDocumentToken() {
        console.info(`PortalDocumentService: search: `, location.search);
        const params = new URLSearchParams(location.search);
        console.info(`PortalDocumentService: params: `, params);
        const dlToken = params.get('dlToken');
        console.info(`PortalDocumentService: dlToken: ${dlToken}`);
        return dlToken;
    }
    static get isPortalDocument() {
        const dlToken = this.getDocumentToken();
        return !!dlToken;
    }
    static getTokenData() {
        const docToken = this.getDocumentToken();
        if (!docToken?.length) {
            return undefined;
        }
        const parts = docToken.split('.');
        const b64 = (parts?.length >= 2) ? parts[1] : undefined;
        const data = b64 ? JSON.parse(window.atob(b64)) : undefined;
        return data;
    }
    static getOriginalFilename() {
        const data = this.getTokenData();
        return data?.originalFilename;
    }
    // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/
    static getDocumentContextData() {
        const documentToken = this.getDocumentToken();
        const documentUrl = this.getDocumentUrl();
        const tokenData = this.getTokenData();
        return {
            documentUrl,
            documentToken,
            documentOriginalFilename: tokenData.originalFilename,
            documentTopLineFileId: tokenData.fileId,
            documentTopLineUserId: tokenData.userId,
            documentTopLineUserEmail: tokenData.userEmail,
        };
    }
    static getDocumentUrl() {
        const dlToken = this.getDocumentToken();
        console.info(`PortalDocumentService: dlToken: ${dlToken}`);
        if (dlToken?.length) {
            return `${window.location.protocol}//api.${window.location.host}/api/v1/pdf_serve/topline?dlToken=${dlToken}`;
        }
        // return FALLBACK_DOC_URL;
        return undefined;
    }
    static getDocumentFileId() {
        const data = this.getTokenData();
        return data['fileId'];
    }
}
