var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import PortalDocumentService from "./services/doc-service";
let PortalApp = class PortalApp extends LitElement {
    renderDoc(docUrl) {
        console.info(`PortalAPp: renderDoc: docUrl: `, docUrl);
        return html `
    <div class="portal-app-doc">
        <portal-doc url="${docUrl}" reportEvents="true"></portal-doc>
    </div>
    `;
    }
    renderUsage() {
        return html `
    <div class="portal-app-usage">
      <h1>To use this service, obtain a link to a secure TopLine document.</h1>
    </div>
    `;
    }
    render() {
        // Gets the dlToken query param from the url and verifies
        // that it is a valid doc token. Then returns an API url to fetch the pdf.
        const docUrl = PortalDocumentService.getDocumentUrl();
        if (docUrl?.length) {
            return this.renderDoc(docUrl);
        }
        return this.renderUsage();
    }
};
PortalApp = __decorate([
    customElement("portal-app")
], PortalApp);
export { PortalApp };
