var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// Binding between the HTML in this component and the PDFViewerApplication
import { getViewerConfiguration, pdfViewerHtml } from '../helpers/pdf-viewer-html';
// import { EventBus } from "../../external/pdf.js/dist/lib/web/event_utils.js";
// import { EventBus } from "pdfjs-dist/lib/web/event_utils.js";
// import { EventBus } from "/Users/timmeade/src/pdf.js/build/lib/web/event_utils.js";
// import { PDFViewerApplication } from '../../external/pdf.js/dist/lib/web/app.js';
// import { AppOptions } from '../../external/pdf.js/dist/lib/web/app_options.js';
import { PDFViewerApplication } from '../../external/pdf.js/dist/lib/web/app.js';
import { WebComponentExternalServices } from '../helpers/pdf-viewer-external';
const EXPOSED_EVENTS = [
    'print',
    'afterprint',
];
let PdfViewer = class PdfViewer extends LitElement {
    url = '';
    reportEvents = false;
    static styles = css `
  :host {
    display: flex;
  }

  #pdf-viewer-container {
    width: 100%;
    min-height: 800px;
  }

  #pdf-viewer-container > * {
    width: 100%;
    min-height: 800px;
  }

  #pdf-viewer-container .textLayer {
    display: none;
  }

  #viewer {
    position: relative;
  }

  #appContainer {
    width: 100%;
  }

  #appContainer {
    width: 100%;
  }

  #viewer {
    position: relative;
  }

  #viewerContainer {
    position: absolute;
    min-height: 800px;
  }

  #sidebarContent {
    min-height: 800px;
  }

  .productLogoToolbarIcon {
    height: 32px;
    width: 53px;
    background-image: url("/assets/css/images/icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 3px;
  }

  `;
    render() {
        return html `<div id="pdf-viewer"></div>`;
    }
    _loaded = false;
    // There is only one, for some reason
    _viewerApp = PDFViewerApplication;
    // For debugging
    get viewerApp() { return this._viewerApp; }
    _appContainer = undefined;
    async reportEvent(eventType, eventData) {
        if (!this.reportEvent) {
            return;
        }
        console.info('PdfViewer: reportEvent: triggering custom event');
        const detail = { eventType, eventData };
        const customEvent = new CustomEvent('PdfViewerEvent', { detail });
        this.dispatchEvent(customEvent);
    }
    async copyPrintDialog() {
        // This is an evil stupid hack to make pdf_print_service.js work
        const componentPrintDialog = this.renderRoot.querySelector('#printServiceDialog');
        if (componentPrintDialog) {
            // Clone the node and its children
            const bodyPrintDialog = window.document.importNode(componentPrintDialog, true);
            window.document.body.appendChild(bodyPrintDialog);
        }
    }
    async initializeComponent() {
        const appContainer = this._appContainer;
        const appConfig = getViewerConfiguration(appContainer);
        const viewerApp = this._viewerApp;
        // Register outselves to dispatch telemetry as custom DOM events
        WebComponentExternalServices.component = this;
        viewerApp.externalServices = WebComponentExternalServices;
        // These lines replicate the behavior of PDFViewerApplication.{run,initialize}()
        // but in a way that gives more control of the promises being fulfilled.
        viewerApp.appConfig = appConfig;
        viewerApp.preferences = viewerApp.externalServices.createPreferences();
        viewerApp.l10n = viewerApp.externalServices.createL10n('en-US');
        await viewerApp._initializeViewerComponents();
        viewerApp.bindEvents();
        viewerApp.bindWindowEvents();
        const eventBus = viewerApp.eventBus;
        if (eventBus) {
            eventBus.on('pagechange', (event) => {
                console.info(`PdfViewer: got pagechange event: `, event);
            });
        }
        if (eventBus && this.reportEvents) {
            EXPOSED_EVENTS.forEach(type => {
                eventBus.on(type, (data) => {
                    console.info(`PdfViewer: got ${type} event: `, data);
                    const detail = { type, data };
                    const customEvent = new CustomEvent('PdfViewerEvent', { detail });
                    this.dispatchEvent(customEvent);
                });
            });
        }
        await this.copyPrintDialog();
        console.info(`PdfViewer: update: PDFViewerApplication initialized`);
        // Load default PDF if there was one provided
        const url = this.url;
        if (url?.length && !this._loaded) {
            await viewerApp.open({ url });
            console.info(`PdfViewer: update: PDFViewerApplication loaded << ${url} >>.`);
            this.reportEvent('documentOpened', { pdfUrl: url });
            this._loaded = true;
        }
    }
    connectedCallback() {
        super.connectedCallback();
        console.info(`PdfViewer: connectedCallback`);
        const doc = this.renderRoot.ownerDocument;
        const hide = (el) => {
            if (el) {
                el.classList.add('hidden');
            }
        };
        if (!this._appContainer) {
            const el = doc.createElement('div');
            el.setAttribute('id', 'appContainer');
            el.innerHTML = pdfViewerHtml;
            hide(el.querySelector('#openFile'));
            hide(el.querySelector('#secondaryOpenFile'));
            this.renderRoot.appendChild(el);
            this._appContainer = el;
        }
        this.initializeComponent()
            .then(() => {
            console.info(`PdfViewer: initialized component`);
        })
            .catch((err) => {
            console.warn(`PdfViewer: error initializing component: `, err);
        });
    }
};
__decorate([
    property({ type: String })
], PdfViewer.prototype, "url", void 0);
__decorate([
    property({ type: Boolean })
], PdfViewer.prototype, "reportEvents", void 0);
PdfViewer = __decorate([
    customElement('pdf-viewer')
], PdfViewer);
export { PdfViewer };
