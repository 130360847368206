export class JitsuEventTarget {
    constructor(eventSource) {
        eventSource.on('event', (event) => {
            const { eventType = 'unknown', eventData } = event;
            const jitsu = window['jitsu'];
            if (jitsu) {
                jitsu('track', `toplineDocPortalEvent-${eventType}`, JSON.parse(JSON.stringify(event)));
            }
        });
    }
}
