import { DefaultExternalServices } from "../../external/pdf.js/dist/lib/web/app.js";
import { BasePreferences } from "../../external/pdf.js/dist/lib/web/preferences.js";
import { DownloadManager } from "../../external/pdf.js/dist/lib/web/download_manager.js";
import { GenericScripting } from "../../external/pdf.js/dist/lib/web/generic_scripting.js";
import { NullL10n } from "../../external/pdf.js/dist/lib/web/l10n_utils.js";
// Needed to enable printing
import '../../external/pdf.js/dist/lib/web/pdf_print_service.js';
window['PDFJSDev'] = 'GENERIC';
const LOCAL_PREFS = 'futuri-topline-pdf.js-prefs';
class WebComponentPreferences extends BasePreferences {
    async _writeToStorage(prefObj) {
        localStorage.setItem(LOCAL_PREFS, JSON.stringify(prefObj));
    }
    async _readFromStorage(prefObj) {
        return JSON.parse(localStorage.getItem(LOCAL_PREFS));
    }
}
export class WebComponentDownloadManager extends DownloadManager {
    component;
    constructor(component) {
        super();
        this.component = component;
    }
    ;
    download(url, blob, filename) {
        // filename = PortalDocumentService.getOriginalFilename();
        console.info(`WebComponentDownloadManager: download: url: ${url} filename: ${filename}`);
        if (this.component) {
            const portalEvent = { eventType: 'download', eventData: { filename } };
            const customEvent = new CustomEvent('PdfViewerEvent', { detail: portalEvent });
            this.component.dispatchEvent(customEvent);
        }
        super.download(url, blob, filename);
    }
}
export class WebComponentExternalServices extends DefaultExternalServices {
    static _component = undefined;
    /**
     * Set the (optional) LitElement that will be used to dispatch telemetry.
     */
    static set component(component) {
        this._component = component;
    }
    static createDownloadManager() {
        return new WebComponentDownloadManager(this._component);
    }
    static createPreferences() {
        return new WebComponentPreferences();
    }
    static createL10n({ locale = "en-US" }) {
        // return new GenericL10n(locale);
        // return new NullL10n();
        return NullL10n;
    }
    static createScripting({ sandboxBundleSrc }) {
        return new GenericScripting(sandboxBundleSrc);
    }
    static reportTelemetry(data) {
        if (this._component) {
            console.info('PdfViewerExternal: reportTelemetry: data: ', data);
            const telemetryType = data.type;
            const eventType = telemetryType?.length ? `telemetry_${telemetryType.toLowerCase()}` : 'telemetry';
            const eventData = data ?? {};
            const docPortalEvent = { eventType, eventData };
            const customEvent = new CustomEvent('PdfViewerTelemetryEvent', { detail: docPortalEvent });
            this._component.dispatchEvent(customEvent);
        }
    }
}
