var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { ApiEventServiceEventTarget } from "../event_targets/apiEventService";
import { GTagEventTarget } from "../event_targets/gtag";
import { JitsuEventTarget } from "../event_targets/jitsu";
import { AnalyticsEventTarget } from "../event_targets/analytics";
import ApiEventService from "../services/api-event-service";
import EventService from "../services/event-service";
import AnalyticsTracker from "../trackers/analytics";
let PortalDoc = class PortalDoc extends LitElement {
    url = "";
    reportEvents = false;
    static styles = css `
    :host {
      display: flex;
    }

    .portal-doc {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .portal-doc .doc-viewer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  `;
    render() {
        console.info(`PortalDoc: render`);
        return html `
      <div class="portal-doc">
        <div class="doc-viewer">
          <pdf-viewer
            id="pdf-viewer"
            url="${this.url}"
            reportEvents="${this.reportEvents ? "true" : "false"}"
            ,
          ></pdf-viewer>
        </div>
      </div>
    `;
    }
    _viewerEventListener = undefined;
    _viewerTelemetryListener = undefined;
    setupEventDelivery() {
        const eventSource = EventService;
        AnalyticsTracker.setup();
        // Add other event targets here
        console.info(`PortalDoc.setupEventDelivery: setting up GTagEventTarget`);
        new GTagEventTarget(eventSource);
        console.info(`PortalDoc.setupEventDelivery: setting up JitsuEventTarget`);
        new JitsuEventTarget(eventSource);
        // console.info(`PortalDoc.setupEventDelivery: setting up SnowplowEventTarget`);
        // new SnowplowEventTarget(eventSource);
        console.info(`PortalDoc.setupEventDelivery: setting up AnalyticsEventTarget`);
        new AnalyticsEventTarget(eventSource);
        console.info(`PortalDoc.setupEventDelivery: setting up ApiEventServiceEventTarget`);
        new ApiEventServiceEventTarget(eventSource);
        console.info(`PortalDoc.setupEventDelivery: starting ApiEventService interval...`);
        ApiEventService.startInterval();
        console.info(`PortalDoc.setupEventDelivery: starting ApiEventService interval...`);
        eventSource.startInterval();
    }
    attachViewerEvents() {
        const el = this.renderRoot?.querySelector("#pdf-viewer");
        if (el) {
            if (!this._viewerEventListener) {
                this._viewerEventListener = function (event) {
                    console.info(`PortalDoc: got pdf viewer event: `, event);
                    console.info(`PortalDoc: got pdf viewer event detail: `, event.detail);
                    let { type: rawType, data: rawData, eventType, eventData } = event.detail;
                    if (rawType?.length) {
                        eventType = `pdf_viewer_${rawType}`;
                        // Eliminate circular references
                        if (rawType == 'print' || rawType == 'afterprint') {
                            rawData = Object.fromEntries(Object.keys(rawData).map(k => [k, true]));
                        }
                        eventData = rawData;
                    }
                    // const docEvent = createDocEvent({ eventType, eventData });
                    const portalEvent = { eventType, eventData };
                    console.info(`PortalDoc: got pdf viewer event: doc event: `, portalEvent);
                    EventService.reportEvent(portalEvent);
                };
            }
            if (!this._viewerTelemetryListener) {
                this._viewerTelemetryListener = function (event) {
                    console.info(`PortalDoc: got viewer telemetry event: `, event);
                    console.info(`PortalDoc: got viewer telemetry event detail: `, event.detail);
                    const docEvent = event.detail;
                    console.info(`PortalDoc: got viewer telemetry event: doc event: `, docEvent);
                    EventService.reportEvent(docEvent);
                };
            }
            el.addEventListener("PdfViewerEvent", this._viewerEventListener);
            el.addEventListener("PdfViewerTelemetryEvent", this._viewerTelemetryListener);
        }
    }
    detachViewerEvents() {
        const el = this.renderRoot?.querySelector("#pdf-viewer");
        if (el && this._viewerEventListener) {
            el.removeEventListener("PdfViewerEvent", this._viewerEventListener);
        }
        if (el && this._viewerTelemetryListener) {
            el.removeEventListener("PdfViewerTelemetryEvent", this._viewerTelemetryListener);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.reportEvents) {
            this.setupEventDelivery();
            // this.attachViewerEvents();
        }
    }
    disconnectedCallback() {
        if (this.reportEvents) {
            this.detachViewerEvents();
        }
        super.disconnectedCallback();
    }
    firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);
        if (this.reportEvents) {
            this.attachViewerEvents();
        }
    }
};
__decorate([
    property({ type: String })
], PortalDoc.prototype, "url", void 0);
__decorate([
    property({ type: Boolean })
], PortalDoc.prototype, "reportEvents", void 0);
PortalDoc = __decorate([
    customElement("portal-doc")
], PortalDoc);
export { PortalDoc };
