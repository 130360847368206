import { v4 as uuidv4 } from 'uuid';
import PortalDocumentService from "../services/doc-service";
export function toDocEvent(portalEvent) {
    const id = uuidv4();
    const meta = PortalDocumentService.getDocumentContextData();
    return {
        id,
        meta,
        portalEvent,
    };
}
;
